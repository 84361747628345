<template>
    <div class="container">
        <div class="section">
            <h1 class="title">Thank You to Our Sponsors</h1>
            <p>
                We would like to thank our sponsors for their support, the Flight Software annual Workshops
                would not be possible without their support.
            </p>
            <p>
                Interested in becoming a sponsor? Contact: <a style="color:blue" href="mailto:info@flightsoftware.org?">info@flightsoftware.org</a>
            </p>
        </div>
        <div style="margin-top: 0px; margin-left: 30px; margin-bottom: 30px">
                <div align="center">
                    <div class="columns is-multiline is-centered">
                        <div class="column is-4" v-for="(sponsor, i) in sponsors" :key="i">
                            <a :href="sponsor.link">    
                                <img
                                    :src="sponsor.hero"
                                    :alt="sponsor.name"
                                    style="margin: auto; min-height: 45px; max-height: 100px; max-width: 75%"
                                />
                            </a>
                        </div>
                    </div>
                    <div></div>
                </div>
        </div>
    </div>
</template>

<script>
import sponsors from "@/sponsors.js";
import _ from "lodash";

export default {
    data() {
        return {
            sponsors: _.sortBy(_.filter(_.filter(sponsors, "public"), "current")),
            founders: _.sortBy(_.filter(sponsors, "founder"))
        };
    }
};
</script>

<style lang="scss" scoped>
.has-equal-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}
</style>
