<template>
    <div class="sponsors">
        <Sponsors />
    </div>
</template>

<script>
import Sponsors from '@/components/Sponsors.vue'
export default {
    name: 'sponsors',
    components: {
        Sponsors
    }
}
</script>